import React from 'react'

class Resume extends React.Component {
  
  render() {
    return (
      <a href="../images/Resume.pdf"></a>
    )
  }
}

export default Resume
